import { CreateActionParams } from '../actions';
import { bookingsBookingsFormLoad } from '@wix/bi-logger-wixboost-ugc/v2';
import { FormStatus } from '../../../../types/form-state';
import { ExperimentsConsts } from '../../../../consts/experiments';
import { FormApi } from '../../../../api/FormApi';
import { IUser, ReportError } from '@wix/yoshi-flow-editor';
import { FormErrors } from '../../../../types/errors';
import { OnError } from '../../../../api/types';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  getControllerState,
  internalActions: { calculatePaymentDetails, errorHandlers },
  context: { biLogger, experiments, formApi, user, reportError },
}: CreateActionParams): InitializeWidget {
  return async () => {
    const [state, setState] = getControllerState();
    const { isBookingsOnEcom, isMemberAreaInstalled } = state;

    biLogger?.report(
      bookingsBookingsFormLoad({
        formId: state.service.formSchema.formId,
      }),
    );
    setState({
      status: FormStatus.IDLE,
    });
    if (isBookingsOnEcom) {
      await calculatePaymentDetails({});
    }
    const isCart = experiments.enabled(ExperimentsConsts.Cart);
    if (isCart) {
      const onError = (error: FormErrors) => errorHandlers.addError(error);
      const memberDetails = await initMemberDetails({
        isMemberAreaInstalled,
        formApi,
        user,
        onError,
        reportError,
      });
      setState({
        memberDetails,
      });
    }
  };
}

const initMemberDetails = async ({
  isMemberAreaInstalled,
  formApi,
  user,
  onError,
  reportError,
}: {
  isMemberAreaInstalled: boolean;
  formApi: FormApi;
  user: IUser;
  onError: OnError;
  reportError: ReportError;
}) => {
  const isLoggedInUser = user.loggedIn;
  const shouldGetMemberDetails = isMemberAreaInstalled && isLoggedInUser;
  const memberDetails = shouldGetMemberDetails
    ? await formApi
        .getMemberDetails({
          id: user.id,
          onError,
        })
        .catch((e) => {
          onError(e);
          reportError(e);
          return undefined;
        })
    : undefined;
  return memberDetails;
};
